/**
 * Video status
 * 
 * This page is used to ensure that all videos load properly
 * 
 * Note: Not very clean code but it gets the job done.
 */

import React, { useState, Fragment } from 'react';
import get from 'lodash/get';
import { Link, graphql } from 'gatsby';
import ProductVideo from '../sections/components/ProductVideo';

const VideoStatus = ({ data }) => {
  const [pagesByVideoSlug] = useState(() => get(data, 'allWordpressPage.nodes', [])
  .filter(page => page.childWordPressAcfVideo)
  .map(page => ({
    path: page.path,
    title: page.title,
    videos: page.acf.sections_page.filter(section => Object.keys(section).length)
  }))
  .reduce((acc, page) => {
    const next = page.videos.reduce((a, b) => {
      if (Array.isArray(get(a, `'[${b.video_path}].pages'`))) {
        a[b.video_path] = {
          video: b,
          pages: a[b.video_path].pages.concat(page, get(acc, `[${b.video_path}].pages`, []))
        };
      } else {
        a[b.video_path] = {
          video: b,
          pages: [page].concat(get(acc, `[${b.video_path}].pages`, []))
        };
      }

      return a;
    }, {});

    return Object.assign(acc, next);
  }, {}));

  console.log('pagesByVideoSlug', pagesByVideoSlug);
    
  if (!pagesByVideoSlug || !Object.keys(pagesByVideoSlug)) {
    return null;
  }

  return (
    <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: '100vh'}}>
      {Object.keys(pagesByVideoSlug).map(videoPath => {
        const pages = get(pagesByVideoSlug, `[${videoPath}].pages`, []);
        const video = get(pagesByVideoSlug, `[${videoPath}].video`, {});
        return (
          <div key={videoPath} style={{ display: 'flex', flexDirection: 'column', marginBottom: '4rem', alignItems: 'center', height: '100%' }}>
            <ProductVideo
              style={{height: 'auto'}}
              isActive
              video={video.video_background}
              toggleModal={() => {}}
              toggleScrolling={() => {}}
              video_path={video.video_path}
              placeholder={video.placeholder}
              showTitleAbove={video.show_title_above_video}
            />
            <div style={{marginTop: '1rem'}}><strong>Path: </strong>{video.video_path}</div>
            {pages.length ? <div style={{marginTop: '1rem'}}><strong>Found on these pages:</strong> {pages.map((page, index) => <Fragment key={`${videoPath}-${page.path}`}><Link to={page.path}>{page.title}</Link>{(index !== pages.length - 1) ? ', ': null}</Fragment>)} </div> : null}
          </div>
        )
      })}
    </div>
  )
}

export default VideoStatus;


export const pageQuery = graphql`
  query {
    allWordpressPage {
      nodes {
        path
        title
        acf {
          sections_page {
            ... on WordPressAcf_video {
              primary
              section_title
              id
              video_path
              video_title
              show_title_above_video
              placeholder {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, srcSetBreakpoints: [1000]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        childWordPressAcfVideo {
          video_path
        }
      }
    }
  }
`
